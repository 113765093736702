<template>
  <div class="home">
    <div class="course-video-box">
      <div class="content-header">
        <div class="header-info">
          <el-row v-if="this.paybtn === 1">
            <el-col :span="3">
              <div class="user-pic">
                <img :src="avatar" alt="" style="width: 101px;height: 102px" />
              </div>
              <p class="placeholder"></p>
            </el-col>
            <el-col :span="18" class="user-info">
              <h2>{{ real_name }}</h2>
              <p>
                <span class="icon-location2"></span>{{ licence_name }} ·
                {{ jiaxiao_name }}
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="course-title">
        <div class="title-info">
          <h2>当前播放：{{ playName }}</h2>
          <ul>
            <li
              v-for="(item, i) in subjectdata"
              :key="item.subject_id"
              @click="getsubjectid(item.subject_id, i)"
            >
              <a v-bind:class="item.classType ? 'active' : ''">
                {{ item.subject_name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <el-row class="course-directory">
        <el-col :span="16" class="course-directory-list">
          <div class="course-video">
            <div id="test"></div>
          </div>
          <div class="directory-list-item">
            <el-tree
              ref="my-tree"
              :data="video_data"
              :props="defaultProps"
              node-key="course_name"
              :default-expanded-keys="[this.playName]"
              :current-node-key="playName"
              highlight-current
              accordion
              @node-click="handleNodeClick"
            >
              <p class="custom-tree-node" slot-scope="{ node, data }">
                <span
                  v-if="node.level == 1"
                  style="color:#000;font-weight: bold;"
                >
                  {{ node.label }}
                </span>
                <span v-if="node.level == 2" style="color:#000;">
                  {{ node.label }}
                </span>
                <span v-if="node.level == 3">{{ node.label }}</span>
                <span v-if="data['cc_id'] != undefined" style="float:right">
                  <span
                    v-if="data['is_finish'] == 0"
                    class="icon-circle-o"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 1"
                    class="icon-circle1"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 2"
                    class="icon-contrast"
                  ></span>
                  <span
                    v-if="data['is_finish'] == 3"
                    class="icon-dot-circle-o"
                  ></span>
                </span>
              </p>
            </el-tree>
            <!-- <h2>{{ item.name }}</h2>
            <dl v-for="item2 in item.children" :key="item2.chapter_id">
              <dt>{{ item2.course_name }}</dt>
              <dd
                v-for="item3 in item2.children"
                :key="item3.chapter_id"
                @click="
                  play_video(
                    item3.cc_id,
                    item3.is_finish,
                    item3.course_name,
                    item3.video_id,
                    item3.duration
                  )
                "
              >
                <span class="icon-play2"></span
                ><a href="#">{{ item3.course_name }}</a>
                <span v-if="item3.is_finish == 0" class="icon-circle-o"></span>
                <span v-if="item3.is_finish == 1" class="icon-circle1"></span>
                <span v-if="item3.is_finish == 2" class="icon-contrast"></span>
                <span
                  v-if="item3.is_finish == 3"
                  class="icon-dot-circle-o"
                ></span>
              </dd>
            </dl> -->
          </div>
        </el-col>
        <el-col :span="5" :offset="1" class="trial-box">
          <div class="trial-item">
            <a @click="tryIt()" style="cursor:pointer"
              ><span class="icon-play3"></span>试看课程</a
            >
          </div>
          <div class="trial-item buy" v-if="this.paybtn === 1">
            <a @click="buycourse()" style="cursor:pointer"
              ><span class="icon-shopping-cart"></span>购买课程</a
            >
          </div>
          <div class="trial-item activation" v-if="this.paybtn === 2">
            <a @click="activation()" style="cursor:pointer"
              ><span class="icon-gesture"></span>激活账户</a
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="购买课程"
      :visible.sync="Buydialog"
      width="50%"
      center
      :before-close="courseClose"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="确认订单信息"></el-step>
        <el-step title="付款"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0">
            <div class="order-info">
              <table width="800" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <td>课程名称</td>
                    <td>课程简介</td>
                    <td>有效期</td>
                    <td width="80">价格 ( 元 )</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="150">
                      {{ this.orderinfo.licence_title }}
                    </td>
                    <td width="280">
                      {{ this.orderinfo.description }}
                    </td>
                    <td width="150">自购买之日起<span>3</span>年内有效</td>
                    <td>{{ this.orderinfo.total_fee / 100 }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="order-info-box">
                <div class="code-box">
                  <input v-model="code" placeholder="输入验证码" />
                  <div
                    class="login-code"
                    style="margin-top:4px"
                    @click="refreshCode"
                  >
                    <!--验证码-->
                    <Identify :identifyCode="identifyCode"></Identify>
                  </div>
                </div>
                <p class="money-box">
                  应付总额：<span>{{ this.orderinfo.total_fee / 100 }}</span
                  >元
                </p>
                <p>
                  <a class="confirm-btn" @click="next" style="cursor:pointer"
                    >确认订单</a
                  >
                </p>
                <div class="agreement-btn">
                  <el-checkbox v-model="checked" style="margin-right:8px"
                    >&nbsp;</el-checkbox
                  >
                  <a @click="innerVisible = true"
                    >购买即代表同意《用户购买协议》</a
                  >
                </div>
                <el-dialog
                  width="30%"
                  title="用户购买协议"
                  :visible.sync="innerVisible"
                  append-to-body
                  :before-close="agreeOn"
                >
                  <div class="agreement">
                    <p>
                      用户在购买本次车学堂收费网络课程之前，请仔细阅读本协议条款。用户应当同意本协议的全部条款，用户完成支付即表示完全接受本协议项下的全部条款。
                    </p>
                    <p>
                      1.除了用户购买本次收费网络课程并私人观看外，对于任何其他未明确授权给用户的收费网络课程及其他服务的权利、收益，由本平台或相关权利人所有。
                    </p>
                    <p>
                      2.用户应妥善保存账户注册时所填写的信息。用户账户注册时所填写的信息将成为平台验证的唯一凭证。因用户账户注册信息丢失造成不能正常登录使用的，本平台不承担任何法律责任。
                    </p>
                    <p>
                      3.用户应自行负责保管、使用、维护其在本平台的账户及账户密码。用户应对其账户及密码采取必要和有效的记忆和保密措施，对于非因本平台原因导致用户账户及密码遗忘、泄漏等所造成的用户损失，本平台无须承担与此有关的任何责任。用户发现其账户、密码遗忘、丢失后，应及时与本平台取得联系，本平台可视情况协助用户恢复其权益。
                    </p>
                    <p>
                      4.本平台收费网络课程的价格另行标明，用户点击“同意”本协议，即视为用户认可本次收费网络课程标明的价格；用户支付成功后，该项服务即时生效。
                    </p>
                    <p>
                      5.如果用户同意购买本次收费网络课程，支付成功后本协议将立即生效，用户可即时观看或在有效期内另择方便时段观看本次收费网络课程视频。如果支付失败，本协议将不发生效力。用户将无权观看本次收费网络课程视频。
                    </p>
                    <p>
                      6.本平台有权根据包括但不限于国家最新颁布或修订的相关法律法规、标准等，对网络课程进行相应的修改、更新。该等修改、更新对用户并无任何不利，不需要提前通知用户或征得用户同意，本平台视为用户接受并认可相关修改、更新行为。
                    </p>
                    <p>
                      7.因用户将个人账户及密码告知他人或与他人共享注册帐户，由此导致任何个人资料泄露的，本平台不负任何责任。
                    </p>
                    <p>
                      8.用户购买收费网络课程，只可以私人观看，不得用于商业、盈利等用途。用户同意不得对授课内容录音录像，不得将网络课程内容复制、销售、出租或授权给任何第三方。如果本平台发现用户存在上述行为，本平台将有权停止用户对本次课程的观看，该用户已支付的费用将不予退还。用户在本平台使用的账户不得出售。如果本平台发现用户擅自出售平台账户，本平台将有权终止该账户的使用，该用户已支付的费用将不予退还。
                    </p>
                    <p>
                      9.用户购买的收费网络课程有一定的有效期。超过有效期，用户将无法观看网络课程视频。用户若想获得继续观看的权限，需再次购买并支付费用。
                    </p>
                    <p>
                      10.用户在同意购买并支付成功后至有效期内首次观看课程前，可以单方解除本协议并申请退款。否则，用户一旦产生了观看记录或者虽未产生观看记录但已过有效期，则不能申请退款，请知悉。
                    </p>
                    <p>
                      11.如果用户在同意购买并支付费用时，使用支付宝或微信形式付款，则用户根据前条申请退款时，本平台将视用户付款方式将相关费用退至用户的支付宝或微信账户内。由于支付宝和微信会产生手续费，该等手续费应由用户承担，所以退费时会被扣除1%的费用。
                    </p>
                  </div>
                  <div class="confirm-box">
                    <a
                      href="#"
                      class="con-btn"
                      @click="agree"
                      style="cursor:pointer"
                      >同意</a
                    >
                  </div>
                </el-dialog>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="1">
            <div class="order-two-content">
              <div class="order-two-left">
                <h2>订单信息</h2>
                <ul>
                  <li>
                    课程名称：<span id="subject">{{
                      this.orderinfo.licence_title
                    }}</span>
                  </li>
                  <li>
                    交易金额：<span id="total_fee">{{
                      this.orderinfo.total_fee / 100
                    }}</span
                    >元
                  </li>
                  <li>购买日期：{{ this.orderinfo.create_time }}</li>
                  <li>
                    有&nbsp;&nbsp;效&nbsp;期：{{ this.orderinfo.valid_time }}
                  </li>
                  <li>
                    订&nbsp;&nbsp;单&nbsp;号：<span id="out_trade_no">
                      {{ this.orderinfo.out_trade_no }}</span
                    >
                  </li>
                </ul>
              </div>
              <div class="order-two-right">
                <h2>付款方式</h2>
                <ul class="choice-ppayment">
                  <li
                    id="alipay"
                    @click="payType(item.paytype)"
                    class="active"
                    style="cursor:pointer;border: 1px solid #ccc;"
                    v-for="(item, index) in paytype"
                    :key="index"
                  >
                    <img
                      v-if="item.paytype === 2"
                      src="@/assets/images/payment_zfb.jpg"
                      alt=""
                    />
                    <img
                      v-if="item.paytype === 2 && type === 2"
                      src="@/assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                    <img
                      v-if="item.paytype === 1"
                      src="@/assets/images/payment_wx.jpg"
                      alt=""
                    />
                    <img
                      v-if="item.paytype === 1 && type === 1"
                      src="@/assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                </ul>
              </div>
              <div class="confirm-box">
                <a class="con-btn" @click="next" style="cursor:pointer"
                  >马上付款</a
                >
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="change-alt">
              <span v-if="pay_status == 0" class="icon-checkmark1"></span>
              <span v-if="pay_status == 1" class="icon-cancel1"></span>
              <p v-if="pay_status == 0">付款成功！</p>
              <p v-if="pay_status == 1">付款失败！</p>
            </div>
            <div class="confirm-box"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <el-dialog
      title="微信支付"
      :visible.sync="wxpaydialog"
      width="40%"
      center
      :before-close="handleClose"
    >
      <div class="wechat-pay">
        <h2>
          支付金额：
          <span>{{ this.orderinfo.total_fee / 100 }}</span>
          元
        </h2>
        <div>
          <img :src="this.wximage" />
          <p>请微信扫码支付</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getChecked,
  getIsPay,
  getLicenceName,
  getResult,
  getTerm,
  removeChecked,
  removeIdcard,
  removeIsPay,
  removeLicenceId,
  removeLicenceName,
  removeParentId,
  removePassword,
  removeResult,
  removeSubjectId,
  removeTerm,
  removeUuid,
  setIsPay
} from '../utils/auth'
import { courserule, getCourses, getSubject } from '../api/courses'
import { confirmorder, createorder, selectorder } from '../api/order'
import Identify from '../components/identify'
import { userActive } from '../api/user'

export default {
  name: 'home',
  components: { Identify },
  inject: ['reload'],
  data() {
    return {
      pay_status: 1,
      wximage: '',
      wxpaydialog: false,
      identifyCodes: '1234567890',
      identifyCode: '',
      code: '',
      orderinfo: [],
      paytype: [],
      active: 0,
      Buydialog: false,
      timerTime: 0,
      type: 2,
      playstatus: '',
      playtext: '',
      progress: 0,
      subjectdata: [],
      term: 0,
      butname: '继续学习',
      avatar: '',
      real_name: '',
      licence_title: '',
      jiaxiao_name: '',
      licence_name: '',
      subject_id: 0,
      video_data: [],
      old_video_data: [],
      rule: [],
      is_pay: 0,
      duration_limit: '',
      is_finish: 0,
      total_study_time: 0,
      period: '',
      progressbar_enable: '',
      playName: '',
      assessNum: 0,
      cc_id: '',
      video_id: '',
      dialogTableVisible: false,
      centerDialogVisible: false,
      innerVisible: false,
      imgpath: '',
      camera: true,
      img: false,
      imgs: false,
      cameracollection: true,
      imgcollection: false,
      imgscollection: false,
      isFrist: false,
      times: 1,
      step: '',
      verify_type: '',
      position: '',
      uuid: '',
      point: 0,
      duration: 0,
      starttime: 0,
      curTime: 0,
      videolist: [],
      idx: 0,
      currvideo: { video_id: 0, cc_id: '' },
      isFlag: 0,
      limitduration: 0,
      nowTime: '',
      sTime: '',
      eTime: '',
      ciRecoderStime: '',
      ciRecoderEtime: '',
      total_duration: '',
      collectionImageData: [],
      distinguishImageData: [],
      timer_duration: 0,
      indexData: [],
      paybtn: 0,
      timer: 0,
      time1: 0,
      selTimer: 0,
      checked: true,
      start: 120,
      end: 3,
      mid: 24,
      defaultProps: {
        children: 'children',
        label: 'course_name'
      }
    }
  },
  created() {
    this.indexData = this.$route.query
    if (this.indexData.licence_id !== undefined) {
      this.licence_id = this.indexData.licence_id
    } else {
      this.licence_id = JSON.parse(getResult()).licence_id
    }

    this.licence_name = getLicenceName()
    //this.licence_id = JSON.parse(getResult()).licence_id
    //this.subject_id = getSubjectId()
    this.term = getTerm()
    getSubject({ licence_id: this.licence_id }).then(res => {
      if (res.code === 0) {
        this.subjectdata = res.result.subject_list
      }
      for (let s = 0; s < this.subjectdata.length; s++) {
        if (s === 0) {
          this.subject_id = this.subjectdata[0].subject_id
          this.subjectdata[0].classType = true
        } else {
          this.subjectdata[s].classType = false
        }
      }
      this.getInfo()
      this.getCourses()
      window.ccH5PlayerJsLoaded = true
      this.setPlayerCallBack()
    })
  },
  methods: {
    handleNodeClick(params) {
      if (params.hasOwnProperty('children') === false) {
        let cc_id = params['cc_id']
        let is_finish = params['is_finish']
        let course_name = params['course_name']
        let video_id = params['video_id']
        let duration = params['duration']
        this.currvideo.video_id = video_id
        this.currvideo.duration = duration
        if (this.rule.order === 0) {
          for (let i = 0; i < this.video_data.length; i++) {
            for (let j = 0; j < this.video_data[i].children.length; j++) {
              for (
                let k = 0;
                k < this.video_data[i].children[j].children.length;
                k++
              ) {
                const data = this.video_data[i].children[j].children[k]
                if (data) {
                  if (cc_id === data.cc_id) {
                    this.period = data.period
                    this.duration = data.duration
                    // this.total_duration = data.
                    data.is_finish = 3
                  } else {
                    data.is_finish = this.old_video_data[i].children[
                      j
                    ].children[k].is_finish
                  }
                }
              }
            }
          }
          this.video_id = video_id
          this.cc_id = cc_id
          this.playName = course_name
          //树状图默认选中
          this.$nextTick(() => {
            this.$refs['my-tree'].setCurrentKey(this.playName)
          })
          this.getAssessnum(video_id)
          this.createPlayer(this.cc_id, this.playName, this.video_id)
        } else {
          this.$message({
            message: '检测到规则为顺序播放，点击无效！',
            type: 'warning'
          })
        }
      }
    },
    //同意
    agree() {
      this.checked = true
      this.innerVisible = false
    },
    agreeOn() {
      this.innerVisible = false
    },
    courseClose() {
      clearInterval(this.selTimer)
      this.active = 0
      this.code = ''
      this.Buydialog = false
    },
    // 重置验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    //付款方式
    payType(type) {
      this.type = type
    },
    //激活
    activation() {
      this.$confirm('确认激活账号, 激活后可进行学习！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          let params = {
            licence_id: this.licence_id,
            licence_title: this.licence_title,
            term: this.term
          }
          userActive(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: '激活成功！',
                type: 'success'
              })
              this.reload()
              this.$router.push({ path: '/catalogs' })
              removeIsPay()
              setIsPay('2')
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '我知道了'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消激活'
          })
        })
    },
    //生成订单
    buycourse() {
      // 初始化验证码
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      createorder({ url: 'https://www.chexuetang.cn/#/catalogs' }).then(res => {
        if (res.code === 0) {
          this.orderinfo = res.result
          this.paytype = res.result.paytype
          this.type = res.result.paytype[0].paytype
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
      this.Buydialog = true
    },
    handleClose(done) {
      this.active = 2
      clearInterval(this.selTimer)
      this.wxpaydialog = false
    },
    //下一步
    next() {
      if (this.active === 0) {
        if (this.code !== '') {
          if (this.code !== this.identifyCode.toLowerCase()) {
            this.$message.error('请填写正确验证码')
          } else {
            if (this.checked !== true) {
              this.$message.error('请先同意本协议')
            } else {
              this.active++
            }
          }
        } else {
          this.$message.error('请填写验证码')
        }
      } else if (this.active === 1) {
        for (let i = 0; i < this.orderinfo['paytype'].length; i++) {
          if (this.orderinfo['paytype'][i]['paytype'] == this.type) {
            this.mid = this.orderinfo['paytype'][i]['mid']
          }
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          paytype: this.type,
          mid: this.mid,
          platid: 2
        }
        confirmorder(params).then(res => {
          if (res.code === 0) {
            this.selOrder()
            if (this.type === 1) {
              this.wxpaydialog = true
              this.wximage = res.result
            }
            if (this.type === 2) {
              let routerData = this.$router.resolve({
                path: '/applyText',
                query: {
                  htmls: res.result
                }
              })
              this.htmls = res.result
              //打开新页面
              window.open(routerData.href, '_ blank')
            }
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
          //console.log(res)
        })
      } else if (this.active === 2) {
        this.active = 0
      }
    },
    //查询订单
    selOrder() {
      this.selTimer = setInterval(() => {
        if (this.start <= this.end) {
          clearInterval(this.selTimer)
          this.$message({
            message: '支付超时！',
            type: 'error',
            onClose: () => {
              this.wxpaydialog = false
            }
          })
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          platid: 2
        }
        selectorder(params).then(res => {
          this.start = this.start - this.end
          if (res.code == 0) {
            this.pay_status = 0
            this.wxpaydialog = false
            this.active = 2
            clearInterval(this.selTimer)
            this.reload()
            this.$router.push({ path: '/catalogs' })
            removeIsPay()
            setIsPay('2')
          } else if (res.code == 1) {
            this.pay_status = 1
          } else {
            clearInterval(this.selTimer)
            this.$message({
              message: '系统错误！',
              type: 'error'
            })
          }
        })
      }, this.end * 1000)
    },
    //用户信息
    getInfo() {
      if (getResult() !== undefined) {
        this.avatar = JSON.parse(getResult()).avatar
        this.real_name = JSON.parse(getResult()).real_name
        this.licence_title = JSON.parse(getResult()).licence_title
        this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
        this.imgpath = JSON.parse(getResult()).checkphoto
      }
    },
    //获取科目
    getsubjectid(subject_id, i) {
      if (getResult() === undefined) {
        for (let s = 0; s < this.subjectdata.length; s++) {
          this.subjectdata[s].classType = false
        }
        this.$set(
          this.subjectdata[i],
          'classType',
          this.subjectdata[i].classType === false
        )
        this.$forceUpdate()
        this.subject_id = subject_id
        this.getCourses()
      }
      if (this.rule.jump === 0) {
        this.$message({
          message: '检测到规则为不能跨科目学习，点击无效！',
          type: 'warning'
        })
      } else if (this.rule.jump === 1) {
        if (this.rule.subject1_duration_limit !== 0 && subject_id !== 1) {
          if (this.total_study_time >= this.rule.subject1_duration_limit) {
            for (let s = 0; s < this.subjectdata.length; s++) {
              this.subjectdata[s].classType = false
            }
            this.$set(
              this.subjectdata[i],
              'classType',
              this.subjectdata[i].classType === false
            )
            this.$forceUpdate()
            this.subject_id = subject_id
            this.getCourses()
          } else {
            this.$message({
              message: '科目一学时不够，禁止跳转科目',
              type: 'warning'
            })
          }
        } else {
          for (let s = 0; s < this.subjectdata.length; s++) {
            this.subjectdata[s].classType = false
          }
          this.$set(
            this.subjectdata[i],
            'classType',
            this.subjectdata[i].classType === false
          )
          this.$forceUpdate()
          this.subject_id = subject_id
          this.getCourses()
        }
      }
    },
    //获取课程
    getCourses() {
      let params = {
        term: 1,
        licence_id: this.licence_id,
        subject_id: this.subject_id,
        province_id: 0,
        city_id: 0,
        jiaxiao_id: 0,
        appid: 1
      }
      // if (JSON.parse(getResult()).is_pay === 2) {
      //   params['province_id'] = JSON.parse(getResult()).province_id
      //   params['city_id'] = JSON.parse(getResult()).city_id
      //   params['jiaxiao_id'] = JSON.parse(getResult()).jiaxiao_id
      //   params['appid'] = JSON.parse(getResult()).user_source
      // } else {
      //   params['province_id'] = 0
      //   params['city_id'] = 0
      //   params['jiaxiao_id'] = 0
      //   params['appid'] = '10000553' //1
      // }
      getCourses(params).then(res => {
        if (res.code === 0) {
          if (res.result.hasOwnProperty('course') === true) {
            this.total_duration = res.result.period
            this.period = res.result.course.period
            this.progress = res.result.course.progress
            this.video_data = res.result.course.course_list
            this.old_video_data = JSON.parse(JSON.stringify(this.video_data))
            this.playName = res.result.course.last_video.course_name
            //树状图默认选中
            this.$nextTick(() => {
              this.$refs['my-tree'].setCurrentKey(this.playName)
            })
            for (let i = 0; i < this.video_data.length; i++) {
              for (let j = 0; j < this.video_data[i].children.length; j++) {
                for (
                  let k = 0;
                  k < this.video_data[i].children[j].children.length;
                  k++
                ) {
                  const data = this.video_data[i].children[j].children[k]
                  if (data) {
                    if (
                      res.result.course.last_video.cc_id === data.cc_id &&
                      res.result.course.last_video.course_name ===
                        data.course_name
                    ) {
                      this.period = data.period
                      this.duration = data.duration
                      // this.total_duration = data.
                      data.is_finish = 3
                      this.video_id = data.video_id
                    }
                  }
                }
              }
            }
            let lastVid = ''
            if (res.result.course.last_video !== null) {
              lastVid = res.result.course.last_video.cc_id
            }
            this.getVideolist(res.result.course.course_list, lastVid)
            this.playVideoList()
          }
        }
      })
    },
    getVideolist(list, lastvid) {
      //let _this = this
      this.videolist = []
      let i = 0
      list.forEach(item => {
        if (item.children) {
          let nodelist = item.children
          nodelist.forEach(node => {
            if (node.children) {
              let leaflist = node.children
              leaflist.forEach(leaf => {
                if (lastvid === leaf.cc_id) {
                  this.idx = i
                }
                // _this.videolist.push(JSON.stringify(leaf))
                this.videolist.push(JSON.stringify(leaf))
                i++
              })
            } //else if (node.cc_id !== "undefined") {
            // _this.videolist.push(node);
            //}
          })
        } //else if (item.cc_id !== "undefined") {
        // _this.videolist.push(item);
        //}
      })
    },
    playVideoList() {
      if (this.idx >= this.videolist.length) this.idx = 0
      this.currvideo = JSON.parse(this.videolist[this.idx])
      let vid = this.currvideo.cc_id
      this.createPlayer(vid, this.currvideo.course_name)
      this.idx++
    },
    //点击播放视频
    play_video(cc_id, is_finish, course_name, video_id, duration) {
      // if (is_finish === 1) {
      //   this.videolist.forEach((item, index) => {
      //     let video = JSON.parse(item)
      //     if (video.cc_id === cc_id) {
      //       this.idx = index
      //       this.currvideo = video
      //     }
      //   })
      //   this.createPlayer(cc_id, course_name)
      // }
      this.currvideo.video_id = video_id
      this.currvideo.duration = duration
      if (this.rule.order !== 1) {
        for (let i = 0; i < this.video_data.length; i++) {
          for (let j = 0; j < this.video_data[i].children.length; j++) {
            for (
              let k = 0;
              k < this.video_data[i].children[j].children.length;
              k++
            ) {
              const data = this.video_data[i].children[j].children[k]
              if (data) {
                if (cc_id === data.cc_id) {
                  this.period = data.period
                  this.duration = data.duration
                  // this.total_duration = data.
                  data.is_finish = 3
                } else {
                  data.is_finish = this.old_video_data[i].children[j].children[
                    k
                  ].is_finish
                }
              }
            }
          }
        }
        this.video_id = video_id
        this.cc_id = cc_id
        this.playName = course_name
        //树状图默认选中
        this.$nextTick(() => {
          this.$refs['my-tree'].setCurrentKey(this.playName)
        })
        this.createPlayer(this.cc_id, this.playName)
      } else if (this.rule.order !== 0) {
        this.$message({
          message: '检测到规则为顺序播放，点击无效！',
          type: 'warning'
        })
      }
    },
    createPlayer(vid, course_name) {
      this.video = document.getElementById('test')
      this.player = window.createCCH5Player({
        vid: vid,
        siteid: 'B3900E03A0A4C537',
        playtype: 1,
        width: '100%',
        height: '100%',
        isShare: false,
        banDrag: false,
        open_barrage: false,
        progressbar_enable: this.progressbar_enable,
        parentNode: this.video,
        realAutoPlay: true,
        closeHistoryTime: 1,
        showRateBtn: false
      })
      this.playName = course_name
      //树状图默认选中
      this.$nextTick(() => {
        this.$refs['my-tree'].setCurrentKey(this.playName)
      })
    },
    nextPlayer() {
      this.player && this.player.destroy()
    },
    setPlayerCallBack() {
      window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
      window.on_CCH5player_ready = this.onPlayerReady
      window.on_player_volumechange = this.onPlayerVolumeChange
      window.on_CCH5player_pause = this.onCCH5playerpause
      window.on_CCH5player_play = this.onCCH5playerplay
      window.on_CCH5player_ended = this.onCCH5playerended
    },
    onCCH5PlayerLoaded() {
      window.ccH5PlayerJsLoaded = true
    },
    onPlayerReady(obj) {
      //console.log('CCH5player_ready', obj)
      //this.player.play()
      //window.cc_js_Player.setVolume(0.3)
    },
    onPlayerVolumeChange(volume, vid) {
      //console.log('设置音量回调:  ', volume, vid)
    },
    onCCH5playerpause() {
      //console.log(this.point)
      clearInterval(this.time1)
      if (this.timerTime >= 60) {
        this.$message({
          message: '试看结束！',
          type: 'warning'
        })
      }
    },
    onCCH5playerplay(video, vid) {
      if (this.timerTime >= 60) {
        this.player.pause()
      }
      this.time1 = setInterval(() => {
        //console.log(this.timerTime)
        this.timerTime += 0.25
        if (this.timerTime >= 60) {
          this.player.pause()
        }
      }, 250)
    },
    onCCH5playerended(video, vid) {
      this.playVideoList()
    },
    showPopupConfirm() {
      this.openCamera()
      this.curTime = Date.parse(new Date())
      this.centerDialogVisible = true
    },
    showcollection() {
      this.openCameracollection()
      this.curTime = Date.parse(new Date())
      this.dialogTableVisible = true
    },
    tryIt() {
      this.player.play()
    }
  },
  mounted() {
    if (getResult() !== undefined) {
      let params = {
        licence_id: this.licence_id,
        term: 1
      }
      courserule(params).then(res => {
        if (res.code === 0) {
          this.rule = res.result
          // this.rule = {
          //   buybtn: 2,
          //   check_interval: 900,
          //   checked: 0,
          //   course_front_ad: 0,
          //   course_study_ad: 0,
          //   drag: 0,
          //   duration_limit: 10800,
          //   exam_button_word: 0,
          //   exam_duration: 0,
          //   exam_isshow: 0,
          //   exam_limit: 0,
          //   exam_pass_template: 0,
          //   exam_submit_content: "",
          //   index_pay_window: 0,
          //   is_collection: 1,
          //   is_pay: 1,
          //   jump: 0,
          //   log_duration_limit: 0,
          //   logout: 1,
          //   order: 1,
          //   photo_num: 0,
          //   playing: 1,
          //   push_period_isshow: 1,
          //   rule_content: "1、请按照顺序学习课程\n2、每天学习课程时间不得超过180分钟\n3、按照要求进行人脸拍照",
          //   rule_name: "2-江西九江初学-购买",
          //   score_isshow: 0,
          //   start: 0,
          //   stop: 0,
          //   study_time_zone: [],
          //   subject1_duration_limit: 162000,
          //   tiku_referee: 0,
          //   unit: 2700,
          // }
          this.duration_limit = this.rule.duration_limit
          this.progressbar_enable = this.rule.drag
          if (this.rule.study_time_zone.length > 0) {
            let myDate = new Date()
            let year = myDate.getFullYear()
            let month = myDate.getMonth() + 1 //获取的数值是0-11，用来表示月份要+1
            let day = myDate.getDate()
            let sTime =
              `${year}-${month}-${day}` + ' ' + this.rule.study_time_zone[0]
            let eTime =
              `${year}-${month}-${day}` + ' ' + this.rule.study_time_zone[1]
            this.nowTime = Date.parse(new Date()) / 1000
            this.sTime = Date.parse(new Date(sTime)) / 1000
            this.eTime = Date.parse(new Date(eTime)) / 1000
          } else {
            this.nowTime = 0
            this.sTime = 0
            this.eTime = 0
          }
          if (getIsPay() < 2 && this.rule.buybtn === 1) {
            this.paybtn = 1
          } else if (getIsPay() < 2 && this.rule.buybtn === 2) {
            this.paybtn = 2
          }
        } else if (res.code === 1) {
          this.rule = res.result
        } else if (res.code === -1) {
          this.$alert('登录过期，请登录后再访问', '登录提示', {
            confirmButtonText: '重新登录',
            callback: action => {
              removeResult()
              location.href = '#/login'
            }
          })
        }
      })
    } else {
      this.progressbar_enable = 0
      this.rule = []
    }
  },
  destroyed() {
    this.player && this.player.destroy()
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
<style scoped>
#test {
  width: 100%;
  height: 500px;
  max-width: 965px;
}

.btn {
  width: 100px;
  height: 36px;
  line-height: 40px;
  text-align: center;
  background: #ff6b3a;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 70px;
  cursor: pointer;
  margin-left: 20px;
}

.desc {
  padding: 0 20px;
}

.desc p {
  font-size: 14px;
  line-height: 30px;
}

.desc p > span {
  color: #ff6b3a;
}

.title {
  margin-right: 10px;
}

.tip {
  text-indent: 2em;
}
.custom-tree-node {
  width: 100%;
}
</style>
